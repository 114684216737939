<template>
  <MainLayout>
    <template v-slot:navbar-title>
      Добавить
    </template>
    <CreateFormLayout>
      <template v-slot:title__text>
        Объект
      </template>

      <template v-slot:title-flex-right>
        <div></div>
      </template>

      <template>
        <!--    Данные компании    -->
        <div>
          <FormCardTitle class="data__text">Данные объекта</FormCardTitle>
          <FormInputBlock>
            <ValidationAutocompleteField
              @input="clearService"
              :search-function="citySearch"
              rules="required"
              label="Город"
              v-if="[70].includes(currentRole)"
              :disabled="!!organization || !!diller"
              v-model="city"
              validate-name="город"
            />
            <ValidationInputField
              rules="required"
              label="Название"
              v-model="name"
              validate-name="название"
            ></ValidationInputField>
            <ValidationInputField
              v-model="address"
              rules="required"
              label="Адрес"
              validate-name="адрес"
            ></ValidationInputField>
            <ValidationSelectField
              :items="objectsType"
              rules="required"
              label="Тип объекта"
              v-model="type"
              validate-name="тип объекта"
            />
            <ValidationAutocompleteField
              :search-function="dillerSearch"
              v-if="[70].includes(currentRole)"
              rules="required"
              :disabled="!!organization || !!dealerId || !!companyId"
              label="Дилер"
              v-model="diller"
              validate-name="дилер"
            />
            <ValidationAutocompleteField
              :search-function="companySearch"
              v-if="[70, 60].includes(currentRole)"
              rules="required"
              label="Компания"
              :disabled="!!companyId"
              v-model="organization"
              validate-name="компания"
            />
            <ValidationAutocompleteField
              :search-function="serviceCompanySearch"
              label="Обслуживающая организация"
              v-model="service_organization"
              :disabled="!!serviceOrganizationId || !city"
              validate-name="обслуживающая организация"
            />
          </FormInputBlock>
        </div>
        <!--    Данные компании    -->
      </template>
      <template v-slot:btns="{ handleSubmit }">
        <a @click="$router.go(-1)" class="btn btn_color_white">
          Отмена
        </a>
        <v-btn
          type="submit"
          @click="handleSubmit(createObject)"
          class="btn btn-green"
        >
          Сохранить
        </v-btn>
      </template>
    </CreateFormLayout>
    <v-overlay :value="loading" z-index="9999">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </MainLayout>
</template>

<script>
import MainLayout from "@/components/layouts/MainLayout";
import CreateFormLayout from "@/components/layouts/CreateFormLayout";
import FormCardTitle from "@/components/createFormBlocks/FormCardTitle";
import FormInputBlock from "@/components/createFormBlocks/FormInputBlock";
import ValidationInputField from "@/components/ValidationField/ValidationInputField";
import ValidationAutocompleteField from "@/components/ValidationField/ValidationAutocompleteField";
import ValidationSelectField from "@/components/ValidationField/ValidationSelectField";
import { getCitiesClearRequest } from "@/helpers/api/cities";
import {
  getOrganizationsByIdRequest,
  getOrganizationsClearByIdRequest,
  getOrganizationsRequest,
  getAllOrganizationsClearRequest
} from "@/helpers/api/organizations";
import {
  actionMessage,
  successMessageDictionary
} from "@/helpers/dictionariesHelper/successMessages";
export default {
  name: "CreateObjectPage",
  components: {
    ValidationSelectField,
    ValidationAutocompleteField,
    ValidationInputField,
    FormInputBlock,
    FormCardTitle,
    CreateFormLayout,
    MainLayout
  },

  props: {
    dealerId: {
      type: [String, Number]
    },
    companyId: {
      type: [String, Number]
    },
    serviceOrganizationId: {
      type: [String, Number]
    }
  },
  data() {
    return {
      loading: false,
      objectsType: [
        { text: "Автостоянка, парковка", value: "parking" },
        { text: "Банк", value: "bank" },
        { text: "Другое", value: "other" },
        { text: "Место проезда автотранспорта", value: "traffic-lane" },
        {
          text: "Многоквартирный дом, жилой комплекс",
          value: "housing-estate"
        },
        {
          text: "Образовательное учреждение",
          value: "educational-institution"
        },
        { text: "Общественное учреждение", value: "community-office" },
        { text: "Офис, бизнес-центр", value: "business-center" },
        { text: "Охраняемая зона", value: "protected-zone" },
        { text: "Промышленное предприятие", value: "industrial-enterprise" },
        { text: "Частный дом, коттедж", value: "apartment-house" }
      ],
      citySearch: async value => {
        const query = {};

        if (typeof value === "string") {
          query.search = value;
        } else {
          query.ids = value;
        }
        const res = (await getCitiesClearRequest(query)).data.results.map(
          el => ({
            text: el.name,
            value: el.id
          })
        );

        return res;
      },
      companySearch: async value => {
        return (
          await getOrganizationsRequest({
            query: {
              ids: value,
              types: "default",
              city: this.city,
              diller: this.diller
            }
          })
        ).data.results.map(el => ({
          text: el.name,
          value: el.id
        }));
      },
      dillerSearch: async value => {
        return (
          await getOrganizationsRequest({
            query: {
              ids: value,
              types: "diller,oppen",
              city: this.city
            }
          })
        ).data.results.map(el => ({
          text: el.name,
          value: el.id
        }));
      },
      serviceCompanySearch: async value => {
        return (
          await getAllOrganizationsClearRequest({
            query: { ids: value, city: this.city }
          })
        ).data.results.map(el => ({
          text: el.name,
          value: el.id
        }));
      }
    };
  },
  computed: {
    currentRole() {
      return this.$store.getters.getCurrentRole;
    },
    address: {
      get() {
        return this.$store.getters.getObjectCreateForm.address;
      },
      set(newValue) {
        this.$store.commit("setObjectCreateFormValue", {
          fieldName: "address",
          value: newValue
        });
      }
    }, //id города
    service_organization: {
      get() {
        return this.$store.getters.getObjectCreateForm.service_organization;
      },
      set(newValue) {
        this.$store.commit("setObjectCreateFormValue", {
          fieldName: "service_organization",
          value: newValue
        });
      }
    }, //id города
    organization: {
      get() {
        return this.$store.getters.getObjectCreateForm.organization;
      },
      set(newValue) {
        this.$store.commit("setObjectCreateFormValue", {
          fieldName: "organization",
          value: newValue
        });
      }
    }, //id города
    city: {
      get() {
        return this.$store.getters.getObjectCreateForm.city;
      },
      set(newValue) {
        this.$store.commit("setObjectCreateFormValue", {
          fieldName: "city",
          value: newValue
        });
      }
    }, //id города
    diller: {
      get() {
        return this.$store.getters.getObjectCreateForm.diller;
      },
      set(newValue) {
        this.$store.commit("setObjectCreateFormValue", {
          fieldName: "diller",
          value: newValue
        });
      }
    }, //diller
    name: {
      get() {
        return this.$store.getters.getObjectCreateForm.name;
      },
      set(newValue) {
        this.$store.commit("setObjectCreateFormValue", {
          fieldName: "name",
          value: newValue
        });
      }
    }, //name,
    type: {
      get() {
        return this.$store.getters.getObjectCreateForm.type;
      },
      set(newValue) {
        this.$store.commit("setObjectCreateFormValue", {
          fieldName: "type",
          value: newValue
        });
      }
    } //name
  },
  created() {
    if (this.currentRole === 60) {
      this.diller = this.$store.getters.getCurrentOrganization;
      getOrganizationsClearByIdRequest({ id: this.diller }).then(res => {
        this.city = res.data.city;
      });
    }
    if (this.currentRole === 30) {
      this.organization = this.$store.getters.getCurrentOrganization;
      getOrganizationsClearByIdRequest({ id: this.organization }).then(res => {
        this.diller = res.data.diller;
        this.city = res.data.city;
      });
    }
    if (this.dealerId) {
      getOrganizationsClearByIdRequest({ id: this.dealerId }).then(res => {
        this.city = res.data.city;
      });
      this.diller = Number(this.dealerId);
    }
    if (this.serviceOrganizationId) {
      getOrganizationsClearByIdRequest({ id: this.serviceOrganizationId }).then(
        res => {
          this.city = res.data.city;
        }
      );
      this.service_organization = Number(this.serviceOrganizationId);
    }
    if (this.companyId) {
      getOrganizationsClearByIdRequest({ id: this.companyId }).then(res => {
        this.city = res.data.city;
      });
      this.organization = Number(this.companyId);
      if (!this.dealerId) {
        getOrganizationsByIdRequest({ id: this.organization }).then(
          response => {
            this.diller = response.data.diller;
          }
        );
      }
    }
  },
  beforeDestroy() {
    this.$store.commit("clearCreateObjectForm");
  },
  methods: {
    clearService() {
      this.service_organization = null;
    },
    createObject() {
      this.loading = true;
      this.$store
        .dispatch("createObject")
        .then(() => {
          this.$store.dispatch(
            "successNotification",
            actionMessage.add(successMessageDictionary.object)
          );
        })
        .finally(() => (this.loading = false));
    }
  }
};
</script>

<style scoped></style>
